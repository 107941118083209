.characters {
  height: 30px;
}

.character {
  line-height: 50px;
  width: 45px;
  font-size: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  margin-left: 0px;
  color: #696969;
  text-align: center;
  border: 1px solid green;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
}

.character--inactive {
  background-color: lightgray;
  color: #69696959 !important;
  box-shadow: none;
}

.character--inactive:first-child {
  background-color: lightgray;
  color: #69696959 !important;
}

.character--selected {
  border: 1px solid blue;
  background-color: white !important;
}
