.menu_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  width: 100%;
  position: relative;
}

.open {
  animation: open 0.5s ease-in-out forwards;
}

.close {
  animation: close 0.5s ease-in-out forwards;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes close {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.open_elements {
  background-color: white;
  width: 100%;
  position: absolute;
  top: 49px;
  left: 0;
  right: 0;
  z-index: 100;
  animation: open 0.5s ease-in-out forwards;
}

.closed {
  max-height: 0;
  overflow: hidden;
}
