.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 12px !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 12px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
  background-color: #000 !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  transition: all 0.3s ease-in-out;
}
.image {
  width: 100%;
  height: 280px;
  overflow: hidden;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text_clapms {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
